<template>
  <div class="icon-library">
    <div class="search-pane">
      名称：
      <Input
        v-model="searchName"
        placeholder="输入图标名称搜索"
        style="width: 200px"
        @on-change="searchIcon"
      ></Input>
    </div>
    <div class="icon-box">
      <div
        :class="[
          'icon-item',
          iconName === item.font_class ? 'x-background' : '',
        ]"
        v-for="(item, index) in iconList"
        :key="index"
        @click="() => iconChoose(item.font_class)"
      >
        <span :class="['iconfont', 'icon-'+item.font_class]"></span>
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="btn-box">
      <Button type="primary" style="margin-right: 16px" @click="submit"
        >确定</Button
      >
      <Button class="margin-left: 16px;" @click="cancel">取消</Button>
    </div>
  </div>
</template>

<script>
import iconJson from "@/assets/iconfont/iconfont.json";
export default {
  name: "",
  props: {
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    let iconList = iconJson.glyphs;
    let iconName = this.icon;
    return {
      iconList,
      iconName,
      searchName: "",
    };
  },
  methods: {
    iconChoose(name) {
      if (this.iconName === name) {
        this.iconName = "";
        return;
      }
      this.iconName = name;
    },
    searchIcon() {
      if (this.searchName) {
        this.iconList = iconJson.glyphs.filter(item => item.name.indexOf(this.searchName) > -1)
      } else {
        this.iconList = iconJson.glyphs
      }
    },
    cancel() {
      this.$emit("on-cancel");
    },
    submit() {
      this.$emit("on-submit", this.iconName);
    },
  },
};
</script>

<style lang="less" scoped>
.icon-library {
  position: absolute;
  top: 0;
  left: 30px;
  z-index: 1;
  padding: 16px;
  width: 700px;
  height: 400px;
  background-color: #f6f6f6;
  .search-pane {
    margin-bottom: 16px;
  }
  .icon-box {
    width: 100%;
    height: 274px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .icon-item {
      width: 100px;
      height: 60px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 10px 10px 0;
      cursor: pointer;
    }
    .x-background {
      background: #d3d3d3;
    }
  }

  .btn-box {
    line-height: 60px;
    text-align: center;
  }
}
</style>